@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,100;1,200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: rgb(238, 233, 233);
  /* background: linear-gradient(
    rgb(221, 221, 221) 5.5%,
    rgb(176, 176, 176) 90.2%
  ); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "Poppins", sans-serif;
}

/* ============== Whatsapp icon =============== */

/* .whatsappIcon {
  position: relative;
} */

.whatsappIcon svg {
  /* width: 50px; */
  font-size: 30px;
  text-decoration: none !important;
  color: #282828;
  /* position: absolute;
  bottom: 5rem;
  right: 0; */
}

/* ============================================ */

.spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 1rem;
}

.spinner img {
  width: 100px;
}

/* ===========  HEAER START ======= */

.header {
  padding: 10px 50px 5px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: rgb(238, 233, 233);
  width: 100%;
  z-index: 9999999;
  top: 0;
}

.headerIcon {
  font-size: 30px;
  cursor: pointer;
}

.headernName img {
  width: 100px;
}

.headernName {
  margin-left: 2rem;
}

.navIcon {
  font-size: 30px;
  font-weight: 900;
  cursor: pointer;
}

.topHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topHead span {
  text-align: center;
  color: white;
  font-size: 25px;
  font-weight: 400;
}

/* ========== SIDE MENU ========= */

td {
  color: white;
  font-size: 22px;
  font-weight: 300;
}

td span a {
  color: white;
  font-size: 16px;
}

td span a:hover {
  color: white;
  text-decoration: underline;
}

td {
  padding-bottom: 10px;
}

.icons {
  padding-top: 5px;
  padding-right: 10px;
}

.menus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

/* .home {
  display: flex;
  align-items: center;
  gap: 1.8rem;
  font-weight: 300;
  color: white;
} */

/* .home span {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
}

.home span a {
  text-decoration: none;
  color: white;
} */

/* .home svg {
  font-size: 20px;
  color: white !important;
} */

/* ==== Icons size ======= */

.homeIcon svg {
  font-size: 22px;
}

.about svg {
  font-size: 17px;
}

.videoIcon svg {
  font-size: 19px;
}

.testimo svg {
  font-size: 20px;
}

.production svg {
  font-size: 24px;
}

.eventactivation svg {
  font-size: 16px;
}

.tech svg {
  font-size: 21px;
}

.btl svg {
  font-size: 22px;
}

.catalog svg {
  font-size: 22px;
}

.ourPartner svg {
  font-size: 22px;
}

.contact svg {
  font-size: 21px;
}

/* ===========  HEAER END ======= */

/* ======= slider ======== */

.cover {
  cursor: pointer;
  position: relative;
}

.cover::before {
  width: 90px;
  height: 300px;
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.cover video,
.whenHover img {
  display: none;
}

.whenHover video {
  width: 90px;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
  transition-duration: 4s;
  transition-delay: 2s;
  transition: all 0.5s ease-in-out !important;
  -webkit-transition: all 0.5s ease-in-out !important;
  border-radius: 5px;
  /* overflow: hidden; */
}

.whenHover video:hover {
  width: 150px;
  display: block;
}

.cover img {
  width: 90px;
  height: 300px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 5px;
  filter: grayscale(100%);
  /* overflow: hidden; */
}

.allItems {
  margin-top: -2rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.allItems div:nth-child(2),
.allItems div:nth-child(4),
.allItems div:nth-child(6),
.allItems div:nth-child(8),
.allItems div:nth-child(10) {
  margin-top: 100px;
}

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(37, 37, 37);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999999999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  /* width: 100%; */
  max-width: 100%;
  /* height: 100%; */
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  border-radius: 0 !important;
  margin: 0 auto;
}

.model video {
  width: auto;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  border-radius: 0 !important;
  margin: 0 auto;
}

.model.open .closeIcon {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 3rem;
  height: 3rem;
  padding: 5px;
  /* background-color: rgba(0, 0, 0, 0.4); */
  color: white !important;
  cursor: pointer;
}

/* ===== side bar ==== */

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgb(230, 28, 58);
  background: linear-gradient(
    250deg,
    rgba(230, 28, 58, 1) 0%,
    rgba(35, 31, 32, 1) 100%
  );
  width: 300px;
  height: 100%;
  padding: 20px;
  z-index: 999999999;
  border-radius: 15px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.sidebar svg {
  color: white;
}

/* ===========  SLIDER END ======= */

.copyRight {
  width: 100%;
  padding: 10px 0px;
  font-size: 16px;
  text-align: center;
}

.copyRight span {
  font-size: 14px;
}

.copyRight span a {
  color: black;
  text-decoration: underline;
}

.footerMobView {
  display: none;
}

.features {
  padding: 20px 50px 0px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.heading-sec-new h1 b {
  color: #e42032;
  /* font-weight: 500; */
}

.soundIcon {
  font-size: 20px;
}

.features h2 {
  font-size: 30px;
  font-family: "Anton", sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
}

/* ===== Our Partners ===== */

.outPartnersHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  text-transform: uppercase;
}

.border-sec-new {
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading-sec-new h1 {
  letter-spacing: 1px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  color: #3f3f3f;
}

.border-sec-new strong {
  width: 100px;
  height: 4px;
  border-radius: 20px;
  background: rgb(230, 28, 58);
  margin-bottom: 20px;
  animation: barsss 3s linear infinite;
}

@keyframes barsss {
  0%,
  100% {
    width: 100px;
  }
  50% {
    width: 30px;
  }
}

.logoSection {
  padding: 0 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
}

.logoBg {
  background-color: #eaeaea;
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  flex-basis: 20%;
}

.logoBg img {
  width: 100px;
}

.logoWidth img {
  width: 120px;
}

.logoWidthLast img {
  height: 80px;
}

.logoWidthSecLast img {
  width: 120px;
}

.logoZain img {
  width: 80px;
}

.logoPanda img {
  width: 120px;
}

/* ============== Event Cards ================= */

.main-card-set {
  padding: 0 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  margin: auto;
}

.card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  color: #000;
  /* width: 300px !important; */
  height: 250px !important;
  flex-basis: 30%;
  cursor: pointer;
  margin-top: 1rem;
}

.card__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  transition: all 0.2s ease-in-out;
}

.card__footer {
  padding: 0 1.5rem;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: grid;
  height: auto;
  padding: 10px 20px;
  align-content: center;
  transition: all 0.2s ease-in-out;
  background: white;
  border-left: 1px solid rgb(186, 186, 186);
  border-right: 1px solid rgb(186, 186, 186);
  border-bottom: 1px solid rgb(186, 186, 186);
}

.card__footer span {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: #282828;
}

/* ============================ */

.card--xcl {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  color: #000;
  box-shadow: 3px 3px 10px 2px #56565677;
  width: 300px !important;
  height: 250px !important;
  flex-basis: 30%;
  border: 7px solid #fff;
  cursor: pointer;
}

.card__img--xcl {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-position: center;
  transition: all 0.2s ease-in-out;
}

.card--xcl:hover .card__img--xcl {
  transform: scale(1.1);
}

.card--xcl:hover .card__footer--xcl {
  bottom: 10px;
}

.card__footer--xcl {
  padding: 0 1.5rem;
  position: absolute;
  bottom: 40%;
  left: 0;
  width: 100%;
  display: grid;
  height: auto;
  padding: 10px 0;
  align-content: center;
  transition: all 0.2s ease-in-out;
  background: hsla(0, 0%, 100%, 0.657);
}

.card__footer--xcl span {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #000;
  text-align: center;
}

.proPoints {
  font-size: 16px;
  font-weight: 500;
}

/* contact us css */

.contactUsForm {
  padding: 0 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact--form {
  width: 85%;
  background: #fff;
  border-radius: 6px;
  padding: 20px 60px 30px 40px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.contact--form .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact--form .content .left-side {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  position: relative;
}

.content .left-side::before {
  content: "";
  position: absolute;
  height: 70%;
  width: 2px;
  right: -15px;
  top: 50%;
  transform: translateY(-50%);
  background: #e61c3a;
}

.content .left-side .details {
  margin: 14px;
  text-align: center;
}

.content .left-side .details i {
  font-size: 30px;
  color: #e61c3a;
  margin-bottom: 10px;
}

.content .left-side .details .topic {
  font-size: 18px;
  font-weight: 500;
}

.content .left-side .details .text-one,
.content .left-side .details .text-two {
  font-size: 14px;
  color: #a4a4a4;
}

.contact--form .content .right-side {
  width: 75%;
  margin-left: 75px;
}

.content .right-side .topic-text {
  font-size: 23px;
  font-weight: 400;
  color: #e61c3a;
}

.right-side .input-box {
  height: 50px;
  width: 100%;
  margin: 12px 0;
}

.right-side .input-box input,
.right-side .input-box textarea {
  height: 100%;
  width: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #f0f1f8;
  border-radius: 6px;
  padding: 0 15px;
  resize: none;
}

.right-side .message-box {
  min-height: 110px;
}

.right-side .input-box textarea {
  padding-top: 6px;
}

.right-side .button {
  display: inline-block;
  margin-top: 12px;
}

.right-side .button input[type="button"] {
  color: #fff;
  font-size: 18px;
  outline: none;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  background: #e61c3a;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button input[type="button"]:hover {
  background: #000;
}

/* =================== About us start ==================== */

.sc-about-group-image {
  position: relative;
}

.sc-about-group-image .sc-group {
  position: absolute;
  bottom: -200px;
  right: 0;
  width: 60%;
}

.jjrAboutImg img {
  min-height: 515px;
}

.sc-about-content-style .sc-heading-area .description {
  font-weight: 100;
  font-size: 20px;
  color: #000;
}

.sc-about-content-style .sc-auother-style-box {
  background: #ffffff;
  border: 2px solid #eeeff1;
  border-radius: 10px;
  padding: 15px 15px 10px;
  position: relative;
}

.sc-about-content-style
  .sc-auother-style-box
  .sc-auother-content
  .sc-auother-text {
  font-weight: 600;
  font-size: 20px;
  color: #03041c;
  font-family: "Inter Tight", sans-serif;
  transition: 0.4s;
  z-index: 99999;
}

.sc-auother-content .des {
  font-size: 13px;
  line-height: 17px;
}

.icon-line svg {
  color: #e42032;
  font-size: 30px;
}

.sc-about-content-style
  .sc-auother-style-box
  .sc-auother-content
  .sc-auother-text
  span {
  font-size: 15px;
  z-index: 9999;
  color: #e42032;
}

.sc-about-content-style .sc-auother-style-box .sc-auother-image img {
  width: 140px;
  height: 100px;
  object-fit: cover;
  padding: 0px 20px;
  border-radius: 50%;
}

.ladyIcon img {
  width: 130px !important;
  height: 100px !important;
}

.sc-about-content-style .sc-auother-style-box .sc-shape-icon {
  position: absolute;
  right: 25px;
  top: 34px;
}

.sc-about-content-style .video-area i:hover {
  color: #e42032;
}

.sc-about-content-style .video-area .sc-title a {
  color: #03041c;
  font-weight: 600;
  font-size: 16px;
}

.sc-business-section-area .sc-heading-area .sub-title {
  color: #fff;
}

.sc-about-left-content .sc-ab-image img {
  margin-bottom: 30px;
}

.sc-about-left-content .sc-ab-image .des {
  display: block;
  margin-bottom: 40px;
  max-width: 595px;
}

.sc-about-content-style .sc-auother-style-box {
  background: #ffffff;
  border: 2px solid #eeeff1;
  border-radius: 10px;
  padding: 15px 15px 10px;
  position: relative;
}

.sc-heading-area .sub-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #6e7075;
  margin-bottom: 5px;
}

.customHeading {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.primary-color {
  color: #e69b00;
  /* color: #e42032; */
  font-weight: 700;
  font-style: italic;
}

/* .italic {
  font-style: italic;
} */

.sc-about-content-style .sc-heading-area .description {
  font-weight: 400;
  font-size: 20px;
  color: #000;
}

/* .sc-heading-area .title {
  
} */

.aboutPara,
.des {
  padding: 0 5px;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
  line-height: 1.6rem;
}

.sc-heading-area .title {
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 40px;
}

.sc-slider-section .sc-slider-content .sc-slider-btn .sc-primary-btn {
  padding: 13px 35px;
}

.sc-mr-20 {
  margin-right: 20px;
}

.sc-primary-btn {
  display: inline-block;
  padding: 13px 35px;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  background-color: #e42032;
  z-index: 1;
  cursor: pointer;
}

.sc-primary-btn:hover {
  background-color: #000;
}

.sc-primary-btn {
  color: #fff !important;
}

.sc-process-system-three .process-icon i {
  width: 90px;
  height: 90px;
  line-height: 90px;
  border: 2px solid #eeeff1;
  border-radius: 3px;
  display: inline-block;
  text-align: center;
  font-size: 38px;
  color: #e42032;
}

.sc-process-system-three .process-content {
  border: 2px solid #eeeff1;
  border-radius: 3px;
  padding: 30px;
  position: relative;
}

.sc-mb-35 {
  margin-bottom: 35px;
}

@media only screen and (max-width: 999px) {
  .sc-group {
    width: 70%;
  }
  .sc-about-content-style {
    margin-top: 100px;
  }
  .sc-ab-image img {
    display: none;
  }
  .slider-btn-area {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 20px;
  }
  .sc-process-system-three .process-icon {
    padding: 50px 0px 0px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .sc-ab-image img {
    display: none;
  }
  .sc-group {
    width: 70%;
    left: 70px;
  }
  .sc-about-content-style {
    margin-top: 100px;
  }
  .slider-btn-area {
    text-align: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .sc-heading-area .title {
    font-size: 24px;
  }
  .sc-process-system-three .process-icon {
    padding: 50px 0px 0px 0px;
  }
}

/* =========Services card ========== */

.addon-services {
  background: url("./assets/service-shape.png");
  background-repeat: no-repeat;
  background-position: 150px 200px;
  padding: 20px;
  height: 350px;
  background-color: #fff;
  box-shadow: 0 0 30px #eee;
  text-align: center;
  border-radius: 10px;
}

.addon-services .services-icon {
  margin-bottom: 20px;
}

.addon-services .services-icon img {
  max-width: unset;
  width: 50px;
  height: 50px;
}

.addon-services .services-text .title {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #000;
  text-decoration: none;
  margin-bottom: 20px;
}

.addon-services .services-text .services-txt {
  margin-bottom: 0;
  font-size: 14px;
  text-align: justify;
  hyphens: auto;
  -webkit-hyphens: auto;
}

/* =================== About us End ==================== */

/*====================== testtimonial start =======================*/

.testimonial-view {
  /*  padding: 50px 15px;*/
  margin-bottom: 70px;
  background: #fff;
  color: #333;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
  position: relative;
}

.testimonial-view:before {
  content: "";
  position: absolute;
  bottom: -52px;
  left: 60px;
  right: 60px;
  height: 100px;
  background: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
  z-index: -1;
}

.testimonial-view:after {
  content: "";
  position: absolute;
  bottom: -27px;
  left: 30px;
  right: 30px;
  height: 100px;
  background: #fff;
  border-radius: 3px;
  -webkit-box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 30px -5px rgba(0, 0, 0, 0.15);
  z-index: -1;
}

@media (min-width: 900px) {
  .testimonial-view {
    margin-left: 50px;
    margin-right: 50px;
    padding: 50px;
  }
  .testimonial-view .carousel-control-prev {
    left: -150px;
  }
  .testimonial-view .carousel-control-next {
    right: -150px;
  }
  .testimonial-view .carousel-control-next-icon,
  .testimonial-view .carousel-control-prev-icon {
    width: 40px;
    height: 40px;
  }
}

.carousel-control-next {
  filter: drop-shadow(2px 4px 6px black);
}

.carousel-control-prev {
  filter: drop-shadow(2px 4px 6px black);
}

.section-header {
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 20px;
  position: relative;
}

.section-header:after {
  content: "";
  height: 4px;
  width: 100px;
  background-color: #e42032;
  position: absolute;
  bottom: 0;
  left: calc(50% - 50px);
}

.testimonial-block {
  padding: 15px;
  height: 400px;
}

@media (min-width: 900px) {
  .testimonial-block {
    padding: 50px;
  }
}

.testimonial-block .testimonial-block_user {
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.testimonial-block .testimonial-block_user .testimonial-block_user_image {
  position: relative;
  margin: 0 30px 30px 15px;
}

.testimonial-block .testimonial-block_user .testimonial-block_user_image img {
  height: 170px;
  width: 170px;
  border: 7px solid #eaeaea;
  border-radius: 170px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.testimonial-block
  .testimonial-block_user
  .testimonial-block_user_image:before {
  content: "";
  height: 120px;
  width: 120px;
  border-radius: 120px;
  background: #e42032;
  position: absolute;
  left: -5px;
  top: -10px;
  z-index: -1;
}

.testimonial-block .testimonial-block_user .testimonial-block_user_image:after {
  content: "";
  height: 85px;
  width: 85px;
  border-radius: 85px;
  background: #e42032ad;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.testimonial-block
  .testimonial-block_user
  .testimonial-block_user_info
  .testimonial-block_user_info_name {
  font-weight: bold;
  padding-bottom: 10px;
  margin-bottom: 10px;
  position: relative;
}

.testimonial-block
  .testimonial-block_user
  .testimonial-block_user_info
  .testimonial-block_user_info_name:after {
  content: "";
  height: 3px;
  width: 50px;
  background-color: #e42032;
  position: absolute;
  bottom: 0;
  left: 0;
}

.testimonial-block .testimonial-block_content {
  text-align: center;
  font-size: 16px;
}

/*====================== testtimonial end =======================*/

/* @media query */

@media only screen and (max-width: 900px) {
  .sidebar {
    overflow-y: scroll;
  }
}

@media (max-width: 950px) {
  .contact--form {
    width: 90%;
    padding: 30px 40px 40px 35px;
  }
  .contact--form .content .right-side {
    width: 75%;
    margin-left: 55px;
  }
}

@media (max-width: 820px) {
  .contact--form {
    margin: 40px 0;
    height: 100%;
  }
  .contact--form .content {
    flex-direction: column-reverse;
  }
  .contact--form .content .left-side {
    width: 100%;
    flex-direction: row;
    margin-top: 40px;
    justify-content: center;
    flex-wrap: wrap;
  }
  .contact--form .content .left-side::before {
    display: none;
  }
  .contact--form .content .right-side {
    width: 100%;
    margin-left: 0;
  }
}

.fixWhatsapp {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 99999;
}

.fixWhatsapp svg {
  font-size: 35px !important;
}

@media only screen and (max-width: 600px) {
  /* .fixWhatsapp svg {
    font-size: 35px !important;
  } */
  .header {
    padding: 8px 20px;
    position: fixed;
    width: 100%;
    z-index: 9999999;
    top: 0;
    background-color: rgb(238, 233, 233);
    box-shadow: 0px 7px 16px 0px rgba(177, 176, 176, 0.5);
    -webkit-box-shadow: 0px 7px 16px 0px rgba(177, 176, 176, 0.5);
    -moz-box-shadow: 0px 7px 16px 0px rgba(177, 176, 176, 0.5);
  }
  .header .headerIcon {
    display: none;
  }
  .headernName img {
    width: 70px;
  }
  .headernName {
    margin-left: 0;
  }
  .allItems {
    padding: 0 20px;
    justify-content: space-between;
    height: auto;
    margin-top: -20px;
  }
  .copyRight {
    font-size: 13px;
    /* position: absolute; */
    /* bottom: 0; */
  }
  .copyRight span {
    font-size: 11px;
  }
  /* .outPartnersHeading {
    padding-top: 5.5rem;
  } */
  .features h2 {
    font-size: 20px;
    font-weight: 200;
  }
  .features {
    padding: 50px 20px 0px 20px;
  }
  .logoSection {
    justify-content: center;
    gap: 1rem;
    padding: 0 10px;
  }
  .logoBg {
    /* width: 50px; */
    flex-basis: 20% !important;
    /* display: flex;
    justify-content: center;
    align-items: center; */
  }
  .logoBg img {
    width: 100px;
  }
  /* .customClass {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  .main-card-set {
    padding: 0 10px;
  }
  .main-card-set .card,
  .card--xcl {
    flex-basis: 100%;
  }
  .contactUsForm {
    padding: 0 10px;
  }
  .right-side .button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .heading-sec-new h1 {
    font-size: 20px;
  }
  .sc-shape-icon {
    display: none;
  }
  .testimonial-block {
    height: 550px;
  }
  .testimonial-block .testimonial-block_content {
    text-align: center;
    font-size: 14px;
  }
  .whatsappIcon svg {
    font-size: 25px;
  }
  .proPoints {
    font-size: 13px;
  }
  .customHeading {
    justify-content: center;
  }
  .title {
    text-align: center;
  }
  td {
    padding-bottom: 14px;
  }
  .testimonial-block_user_info h2 {
    font-size: 22px;
  }
  .testimonial-block_user_info h4 {
    font-size: 16px !important;
    font-weight: 300;
  }
  .footerMobView {
    display: block;
  }
  .footerDesktopView {
    display: none;
  }
  .sc-auother-style {
    flex-direction: column;
    justify-content: center;
    gap: 2px;
  }
  .sc-about-group-image .sc-group {
    bottom: -120px;
  }
  .jjrAboutImg img {
    min-height: 30px !important;
  }
  .sc-auother-text {
    text-align: center;
  }
  .aboutPara {
    font-size: 15px;
  }
}

@media (max-width: 1024px) {
  .jjrAboutImg img {
    min-height: 670px;
  }
}

@media only screen and (max-width: 320px) {
  .addon-services .services-text .services-txt {
    font-size: 13px;
  }
}

/* =================================================== */

.testimonial-block_user_info h4 {
  font-size: 20px;
  color: gray;
  font-weight: 400;
}

.carousel-control-prev,
.carousel-control-next {
  height: 30%;
  margin-top: 5rem;
}

.prev-Icon,
.next-Icon {
  margin-top: 7rem;
}

.SwipeVideo video {
  width: 100%;
  height: 100vh;
}

.SwipeVideoIcone {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #000;
  z-index: 99999999;
}
